import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import useSound from "use-sound";

import { makeStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemSecondaryAction,
  Badge,
} from "@material-ui/core";

import CommentIcon from "@material-ui/icons/Comment";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import api from "../../services/api";
import {
  calledCreate,
  calledWindowUpdate,
  calledInit,
  calledHasNonNewMessage,
} from "../../store/actions/called";

import { COOKIE_USER, COOKIE_DEPARTMENT } from "../../configs/constants";

import "./styles.css";

import meeToo from "../../assets/sound/me-too.mp3";

const Called = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const calledReducer = useSelector((state) => state.calledReducer);
  const [cookies] = useCookies([COOKIE_USER, COOKIE_DEPARTMENT]);
  const [simultaneousCalls, setSimultaneousCalls] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newCalledSound] = useSound(meeToo, { volume: 0.01 });

  // useEffect(() => {
  //     const init = async () => {
  //         await getCalled()
  //     }
  //     init();
  // }, []);

  const getCalled = useCallback(async () => {
    try {
      dispatch(calledInit());
      setLoading(true);
      const { data } = await api.get(
        `chat/departments/${cookies[COOKIE_DEPARTMENT].id}/called`
      );
      dispatch(calledCreate(data.data));
    } catch (error) {
      //
    }
    setLoading(false);
  }, [cookies, dispatch]);

  useEffect(() => {
    const init = async () => {
      cookies[COOKIE_DEPARTMENT] &&
        cookies[COOKIE_DEPARTMENT].id &&
        setSimultaneousCalls(cookies[COOKIE_DEPARTMENT].simultaneous_calls);
      cookies[COOKIE_DEPARTMENT] &&
        cookies[COOKIE_DEPARTMENT].id &&
        (await getCalled());
    };
    init();
  }, [cookies, getCalled]);

  const playSound = useCallback(() => {
    calledReducer.called.length > 0 && newCalledSound();
  }, [calledReducer.called.length, newCalledSound]);

  useEffect(() => playSound(), [calledReducer.called, playSound]);

  const handleClick = (index) => {
    dispatch(calledWindowUpdate(index));
  };

  const SingleItem = ({ text }) => {
    return (
      <ListItem>
        <ListItemText primary={text} />
      </ListItem>
    );
  };

  return (
    <>
      <div id="LeftAttending" className={classes.contentLeftAttending}>
        <div className={classes.contentLeftHeader}>
          <div>EM ATENDIMENTO</div>
          <div>
            {calledReducer.called.length} / {simultaneousCalls}
          </div>
        </div>
        <List>
          {loading && <SingleItem text="buscando dados..." />}
          {!loading && calledReducer.called.length === 0 && (
            <SingleItem text="Nenhum atendimento." />
          )}
          {calledReducer.called.map((item, index) => (
            <ListItem
              key={item.id}
              onClick={() => {
                handleClick(index);
                dispatch(calledHasNonNewMessage(item.id));
              }}
              selected={calledReducer.windowActive === index}
            >
              <ListItemAvatar>
                <Avatar>
                  <FontAwesomeIcon icon={faComments} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={classes.ListItemText}
                primary={`${item.conversation.client.name}`}
                secondary={
                  <div>
                    {item.conversation.operation_city && (
                      <div className={classes.city}>{item.conversation.operation_city.name } ({item.conversation.operation_city.state})</div>
                    )}
                  </div>
                }
              />
              <ListItemSecondaryAction>
                {/* <IconButton edge=?"end" aria-label="comments"> */}
                {item.hasNewMessages && calledReducer.windowActive !== index && (
                  <Badge badgeContent="!" color="error">
                    <CommentIcon />
                  </Badge>
                )}

                {/* </IconButton> */}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentLeftWaiting: {
    paddingTop: 10,
  },
  ListItemText: {
    lineHeight: "1em",
  },
  contentLeftHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 10px",
    color: "#43525A",
    fontSize: 15,
    lineHeight: "1.2rem",
    fontWeight: "bold",
  },
  city: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
}));

export default Called;
