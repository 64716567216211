import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import useSound from "use-sound";
import { confirmAlert } from "react-confirm-alert"; // Import
import TimeAgo from "timeago-react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import * as timeago from "timeago.js";
import pt_BR from "timeago.js/lib/lang/pt_BR";

import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "@material-ui/core/Modal";
import { queueCreate, queueInit } from "../../store/actions/queue";
import api from "../../services/api";

import { sysCreate } from "../../store/actions/system";
import { COOKIE_USER, COOKIE_DEPARTMENT } from "../../configs/constants";

import swiftly from "../../assets/sound/swiftly.mp3";

const Queue = () => {
  timeago.register("pt_BR", pt_BR);

  const dispatch = useDispatch();
  const classes = useStyles();
  const queueReducer = useSelector((state) => state.queueReducer);
  const calledReducer = useSelector((state) => state.calledReducer);
  const sysReducer = useSelector((state) => state.systemReducer);
  const [cookies] = useCookies([COOKIE_USER, COOKIE_DEPARTMENT]);
  const [loading, setLoading] = useState(false);
  const [simultaneousCalls, setSimultaneousCalls] = useState(0);
  const [newClientSound] = useSound(swiftly, { volume: 0.01 });

  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(true);

  // useEffect(() => {
  //     const init = async () => {
  //         await getQueue()
  //     }
  //     init();
  // }, []);

  useEffect(() => {
    setOpen(sysReducer.system.modal);
  }, [sysReducer]);

  const playSound = useCallback(() => {
    queueReducer.queue.length > 0 && newClientSound();
  }, [newClientSound, queueReducer.queue.length]);

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const getQueue = useCallback(async () => {
    try {
      dispatch(queueInit());
      setLoading(true);
      const { data } = await api.get(
        `admin/departments/${cookies[COOKIE_DEPARTMENT].id}/queue`
      );
      dispatch(queueCreate(data.data));
    } catch (error) {
      //
    }
    setLoading(false);
  }, [cookies, dispatch]);

  const requestCalled = async (id) => {
    try {
      const response = await api.post(`chat/operator-request/called/${id}`);
      if(response.data.status === false) {
        confirmAlert({
          title: `Atenção`,
          message: response.data.message,
          closeOnEscape: false,
          closeOnClickOutside: false,
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        setOpen(false);
      } else {
        dispatch(sysCreate({ modal: true })); 
      }
    } catch (error) {
      confirmAlert({
        title: `Erro.`,
        message: `Ocorreu um erro ao solicitar o início do atendimento.`,
        closeOnEscape: false,
        closeOnClickOutside: false,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      setOpen(false);
    }
  };

  useEffect(() => playSound(), [playSound, queueReducer]);

  useEffect(() => {
    const init = async () => {
      cookies[COOKIE_DEPARTMENT] &&
        cookies[COOKIE_DEPARTMENT].id &&
        setSimultaneousCalls(cookies[COOKIE_DEPARTMENT].simultaneous_calls);
      cookies[COOKIE_DEPARTMENT] &&
        cookies[COOKIE_DEPARTMENT].id &&
        (await getQueue());
    };
    init();
  }, [cookies, getQueue]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Requisitando atendimento...</h2>
      <p id="simple-modal-description">
        Estamos processando sua requisição, por favor, aguarde
      </p>
    </div>
  );

  const handleClick = (item) => {
    if (calledReducer.called.length >= simultaneousCalls) {
      confirmAlert({
        title: `Você atingiu o limite de ${simultaneousCalls} atendimentos simultâneos.`,
        message: `Finalize os atendimentos em andamento.`,
        closeOnEscape: false,
        closeOnClickOutside: false,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    } else {
      confirmAlert({
        message: "Deseja assumir este atendimento?",
        title: `#${item.conversation.id} - ${item.conversation.client.name}`,
        closeOnEscape: false,
        closeOnClickOutside: false,
        buttons: [
          {
            label: "Sim",
            onClick: () => requestCalled(item.id),
          },
          {
            label: "Não",
          },
        ],
      });
    }
  };

  const SingleItem = ({ text }) => {
    return (
      <ListItem>
        <ListItemText primary={text} />
      </ListItem>
    );
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <div id="LeftWaiting" className={classes.contentLeftWaiting}>
        <div className={classes.contentLeftHeader}>
          <div>EM ESPERA</div>
          <div>
            {queueReducer.queue.length > 0 && queueReducer.queue.length}
          </div>
        </div>
        <List>
          {loading && <SingleItem text="buscando dados..." />}
          {!loading && queueReducer.queue.length === 0 && (
            <SingleItem text="Nenhum cliente em espera." />
          )}
          {queueReducer.queue.map((item) => (
            <ListItem key={item.id} onClick={() => handleClick(item)}>
              <ListItemAvatar>
                <Avatar>
                  <FontAwesomeIcon icon={faUser} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${item.conversation.client.name}`}
                secondary={
                  <div>
                    {item.conversation.operation_city && (
                      <div className={classes.city}>{item.conversation.operation_city.name } ({item.conversation.operation_city.state})</div>
                    )}
                    <TimeAgo datetime={item.created_at} locale="pt_BR" />
                  </div>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentLeftWaiting: {
    paddingTop: 10,
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  contentLeftHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 10px",
    color: "#43525A",
    fontSize: 15,
    lineHeight: "1.2rem",
    fontWeight: "bold",
  },
  city: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
}));

export default Queue;
