import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import api from "../../services/api";
import { logout } from "../../services/auth";
import { setMessage } from "../../store/actions/message";

import { COOKIE_USER, COOKIE_DEPARTMENT } from "../../configs/constants";

const FastMessage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [cookies, removeCookie] = useCookies([COOKIE_USER, COOKIE_DEPARTMENT]);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [filter, setFilter] = useState("");

  const handleLogout = useCallback(() => {
    logout();
    removeCookie(COOKIE_USER);
    removeCookie(COOKIE_DEPARTMENT);
    return history.push("/");
  }, [history, removeCookie]);

  const getMessages = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get("admin/messages?per_page=200");
      setMessages(data.data.data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return handleLogout();
        }
      }
    }
    setLoading(false);
  }, [handleLogout]);

  const handleClick = (item) => {
    dispatch(setMessage((item.message ? item.message : item.name)));
    setFilter("");
  };

  const SingleItem = ({ text }) => {
    return (
      <ListItem>
        <ListItemText primary={text} />
      </ListItem>
    );
  };
  useEffect(() => {
    const init = async () => {
      await getMessages();
    };
    init();
  }, [getMessages]);

  return (
    <>
      <TextField
        className={classes.textFieldFilter}
        placeholder="Pesquisar mensagem"
        onChange={(e) => setFilter(e.target.value)}
        value={filter}
      />
      <List>
        {loading && <SingleItem text="buscando dados..." />}
        {!loading && messages.length === 0 && (
          <SingleItem text="Nenhuma mensagem." />
        )}
        {messages
          .filter((item) =>
            item.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((item) => (
            <ListItem
              key={item.id.toString()}
              onClick={() => handleClick(item)}
            >
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
      </List>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentRightHeader: {
    color: "#43525A",
    fontSize: 15,
    paddingLeft: 10,
    fontWeight: "bold",
  },
  textFieldFilter: {
    width: "100%",
    padding: 0,
  },
}));

export default FastMessage;
